.tt-product-section {
    background-image: url(../images/fifth-section-bg.png);
    color: $main-body-color;
    padding-top: 5rem;
    padding-bottom: 5rem;
    padding-inline: 23px;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: 992px) {
        & .featured-text {
            padding: 0 0 0 2rem;
            border-left: 0.5rem solid $main-green;
        }
    }

    &-puzzles-cloud {
        background-image: url(../images/puzzlesCloudHome.png);
        border: 1px solid rgba(0, 0, 0, 0.8);
        font-size: 30px;
        text-align: center;
        height: 420px;
        width: 634px;
        margin: 2px 2px 2px 2px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        span {
            strong {
                color: #318ce7;
            }
        }

        .tt-product-section-button:hover {
            background-color: #318ce7;
            border: 1px solid #318ce7;
        }
    }

    @media (max-width: 991px) {
        &-puzzles-cloud {
            width: 534px; 

        }
      }

      @media (max-width: 661px) {
        &-puzzles-cloud {
            width: 334px; 

        }
      }

    &-kolumbo {
        background-image: url(../images/Kolumbo.png);

        span {
            strong {
                color: #c10011;
            }
        }

        .tt-product-section-button:hover {
            background-color: #c10011;
            border: 1px solid #c10011;
        }
    }

    &-alertgen {
        background-image: url(../images/Alertgen.png);

        span {
            strong {
                color: #863cff;
            }
        }

        .tt-product-section-button:hover {
            background-color: #863cff;
            border: 1px solid #863cff;
        }
    }

    &-go-park {
        background-image: url(../images/goPark.png);

        span {
            strong {
                color: $main-green;
            }
        }

        .tt-product-section-button:hover {
            background-color: $main-green;
            border: 1px solid $main-green;
        }
    }

    &-button {
        padding: 8px 10px 8px 10px;
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        font-family: "Gilroy ExtraBold";
        background-color: #02010100;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 10px;
        margin-top: 30px;
        -webkit-font-smoothing: antialiased;
    }

    &-product-cover {
        background-color: #1F1F39A8;
        font-weight: bold;
        z-index: 2;
        width: 100%;
        height: 100%;
        text-align: center;
    }

    &-product-cover:hover {
        background-color: #1f1f39d4;
        transition: 0.3s;

        .text-up-heading {
            top: -30px !important;
        }
    }

    &-product-card-text {
        font-size: 23px;
        color: $main-white;
    }
}

.rounded-link {
    display: contents;
    color: #fff;
    border-radius: 10px;
}

@media (max-width: 992px) {
    .tt-product-section {
        padding-top: 1rem;
    }
}