.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 15px rgba(50, 50, 93, 0.2);
  height: 5.5rem;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &.header-absolute {
    background-color: #18182f;
    border-bottom: 1px solid $main-white;
    position: fixed;
    z-index: 100;
    width: 100%;
  }
}
