.QA-section {
    color: $main-white;
    font-family: "Gilroy Light", Sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.6em;
    letter-spacing: 1px;
    background: $main-blue;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 0;
    position: relative;
    padding-inline: 23px;

    &-padding {
        padding-inline: 110px !important;
        padding-top: 100px !important;
    }

    @media (max-width: 1200px) {
        &-padding {
            padding-inline: 30px !important;

        }
    }

    @media (max-width: 992px) {
        &-padding {
            padding-top: 30px !important;

        }
    }

    &-section-team {
        background-image: url(../images/quality-assurance-right-cloud.png);
        background-position: bottom;
        background-size: contain;
        background-repeat: no-repeat;
        height: 400px;
    }

    @media (max-width: 500px) {
        .QA-section-section-team {
            height: 200px;
        }
    }

    &-border {
        border-style: solid;
        border-color: #CCCCCC;
        border-width: 0px 1px 0px 1px;
    }

    &-second-title {
        color: #222222;
        font-family: "Gilroy ExtraBold", Sans-serif;
        font-size: 30px;
        font-weight: 700;
        padding-top: 20px;
        -webkit-font-smoothing: antialiased;
    }

    &-first-title {
        color: #ffff;
        font-family: "Gilroy ExtraBold", Sans-serif;
        font-size: 30px;
        font-weight: 700;
        padding-top: 20px;
        -webkit-font-smoothing: antialiased;
    }

    @media (max-width: 992px) {
       
    }

    &-second {
        background-image: url(../images/fifth-section-bg.png);
        padding-inline: 23px;

        &-padding {
            padding-inline: 110px !important;
        }

        @media (max-width: 1200px) {
            &-padding {
                padding-inline: 30px !important;
            }
        }
    }

    &-title-page {
        color: $main-green;
        font-family: "Gilroy ExtraBold", Sans-serif;
        font-size: 33px;
        font-weight: 800;
        line-height: 1.4em;
        letter-spacing: 2px;
        -webkit-font-smoothing: antialiased;
    }

    &-title-page:after {
        content: '';
        display: block;
        margin-top: 5px;
        height: 5px;
        width: 90px;
        background: #fff;
    }

    &-software-failures {
        padding: 110px calc(var(--bs-gutter-x) * 0.5) 45px calc(var(--bs-gutter-x) * 0.5);

        h3 {
            color: $main-white;
            font-family: "Gilroy ExtraBold", Sans-serif;
            font-size: 21px;
            font-weight: 800;
            -webkit-font-smoothing: antialiased;
        }

        &-card-name {
            min-height: 70px;
        }

        &-desc {
            margin-top: 15%;
        }

        @media (max-width: 992px) {
            &-card-name {
                min-height: 0px;
                margin-bottom: 5%;
            }

            &-desc {
                margin-top: 5%;
                margin-bottom: 5%;
            }
        }
    }

    @media (max-width: 1210px) {
        &-software-failures {
            padding: 22px calc(var(--bs-gutter-x) * 0.5) 15px calc(var(--bs-gutter-x) * 0.5);
        }
    }

    &-software-failures-number {
        color: $main-green;
        font-family: "Gilroy ExtraBold", Sans-serif;
        font-size: 54px;
        font-weight: 900;
        letter-spacing: 3px;
        -webkit-font-smoothing: antialiased;

        &-paragraph {
            margin-top: 40px;
        }

        @media (max-width: 1399px) {
            &-paragraph {
                margin-top: 60px;
            }
        }

        @media (max-width: 991px) {
            &-paragraph {
                margin-top: 10px;
            }
        }
    }

    &-software-failures-desc {
        color: $main-white;
        font-family: "Gilroy ExtraBold", Sans-serif;
        font-size: 20px;
        font-weight: 900;
        -webkit-font-smoothing: antialiased;

    }

    &-blue-line h2::after {
        content: '';
        display: block;
        bottom: 0;
        left: 0;
        height: 5px;
        width: 90px;
        background: $main-green;
    }

    .border-right-failure {
        border-right: 1px solid #dee2e6;
    }

    @media (max-width: 991px) {
        .border-right-failure {
            border: none;
            border-bottom: 1px solid #707070;
            margin-top: 5%;
        }

        .QA-section-margin-top-mobile {
            margin-top: 5%;
        }
    }

    &-software-bug {
        border-style: solid;
        border-width: 1px 1px 1px 1px;
        border-color: #707070;
        transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
        margin-top: 30px;
        margin-bottom: 0px;
        padding: 30px 0px 30px 0px;
        border-radius: 10px 10px 10px 10px;
        background-color: #32324F;
        text-align: center;

        &-dot {
            padding: 20px 0px 20px 0px;

        }

        &-blue-dot {
            span {
                width: 16px;
                height: 16px;
                display: inline-block;
                background: $main-green;
                border-radius: 50%;
                text-align: center;
            }
        }

        &-cost {
            text-align: center;
            color: $main-white;
            font-family: "Gilroy ExtraBold", Sans-serif;
            font-size: 33px;
            font-weight: 900;
            -webkit-font-smoothing: antialiased;

            @media (max-width: 991px) {
                &-padding {
                    padding-bottom: 10px;
                    padding-top: 10px;
                }
            }
        }


        &-desc {
            color: $main-white;
            font-family: "Gilroy Light", Sans-serif;
            font-size: 17px;
            font-weight: 300;
            margin-top: 16px;
        }
    }

    &-why-use-qa {
        font-family: "Gilroy ExtraBold", Sans-serif;
        font-size: 21px;
        font-weight: 800;
        display: flex;
        -webkit-font-smoothing: antialiased;

        @media (min-width: 992px) {
            &-margin {
                margin-bottom: 100px;
            }
        }
    }

    @media (max-width: 991px) {
        &-why-use-qa {
            margin-bottom: 30px;
            font-size: 19px;
        }
    }


    &-quote {
        text-align: center;
        color: $main-white;
        font-family: "Gilroy ExtraBold", Sans-serif;
        font-size: 28px;
        font-weight: 700;
        line-height: 45px;
        -webkit-font-smoothing: antialiased;
    }

    @media (max-width: 991px) {
        &-quote {
            font-size: 25px;
            line-height: 40px;
        }
    }

    &-card {
        display: flex;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        text-align: center;

        &-first {
            width: 33.333%;
            background-color: $main-white;
        }

        &-grey {
            background-color: #E6EAF0;
            width: 33.333%;
        }

        &-text-title {
            color: #222;
            font-size: 21px;
            font-weight: 700;
            text-align: center;
            padding-top: 20px;
            font-family: 'Gilroy ExtraBold';
            -webkit-font-smoothing: antialiased;
        }

        &-box {
            padding: 67px 15px 67px 15px;
            width: 100%;
            height: 300px;
            overflow-y: auto;
        }

        &-box:hover {
           cursor: pointer;
           text-decoration: underline;
        }
    }

    @media (max-width: 1000px) {
        &-card {
            display: block;

            &-first {
                width: 100%;
            }

            &-grey {
                width: 100%;
            }
        }
    }

    &-hide {
        display: none;
    }

    &-how-we-test {
        padding-top: 60px;

        ul {
            list-style: disc outside;
            margin: 0 0 15px 30px;
            color: #101010;
            font-family: "Gilroy Light", Sans-serif;
            font-size: 22px;
            font-weight: 300;
            line-height: 50px;
        }
    }

    @media (max-width: 991px) {
        &-how-we-test {
            padding-top: 20px;

            ul {
                line-height: 40px;
            }
        }
    }

    &-title {
        margin-top: 20px;
        font-size: 20px;
        font-weight: 700;
        font-family: "Gilroy ExtraBold";
        text-transform: uppercase;
        -webkit-transition: color 0.1s ease 0s;
        -moz-transition: color 0.1s ease 0s;
        -ms-transition: color 0.1s ease 0s;
        -o-transition: color 0.1s ease 0s;
        transition: color 0.1s ease 0s;
        -webkit-font-smoothing: antialiased;
    }

    &-desc {
        font-size: 17px;
        font-family: "Gilroy Light";
    }

    &-industries {
        text-align: center;

        &-number {
            color: $main-green;
            font-family: "Gilroy ExtraBold", Sans-serif;
            font-weight: 900;
            -webkit-font-smoothing: antialiased;

            &-flex {
                display: flex;
                justify-content: center;

                &-size {
                    display: block;
                    font-size: 70px;
                    line-height: 18px;
                    color: $main-green;
                    font-family: "Gilroy ExtraBold", Sans-serif;
                    font-weight: 900;
                    -webkit-font-smoothing: antialiased;
                }

                @media (max-width: 991px) {
                    &-size {
                        font-size: 55px;
                    }
                }

                &-desc {
                    color: $main-white;
                    font-family: "Gilroy Light", Sans-serif;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 25px;
                }
            }
        }

        &-top {
            margin-top: 140px !important;
        }

        @media (max-width: 991px) {
            &-top {
                margin-top: 60px !important;
            }

        }
    }

    &-second {
        
        .container {
            .consulting {
                ul {
                    li {
                        span {
                            font-family: "Gilroy Light", Sans-serif;
                            font-size: 20px;
                            font-weight: 300;
                        }
                    }

                    li::marker {
                        color: $main-green !important;
                        font-size: 1.8em;
                    }
                }
            }
        }

        &-paragraph {
            color: #404040;
            font-family: "Gilroy Light", Sans-serif;
            font-size: 20px;
            font-weight: 400;
            line-height: 1.6em;
            letter-spacing: 1px;
        }

        @media (max-width: 991px) {
            &-paragraph {
                font-size: 15px;
            }
        }

        &-directions {
            font-family: "Gilroy ExtraBold", Sans-serif;
            font-size: 21px;
            font-weight: 900;
            -webkit-font-smoothing: antialiased;
        }

        .QA-section-section-directions-ul {
            ul {
                margin-left: -40px;

                li {
                    align-items: center;
                    display: flex;
                    font-size: inherit;
                    margin-bottom: 20px;

                    span {
                        font-size: 19px;
                    }
                }
            }
        }
    }

    @media (max-width: 991px) {
        &-second {
            .container {
                .consulting {
                    ul {
                        li {
                            span {
                                font-size: 19px;
                                line-height: 1.2em;
                            }
                        }

                        li::marker {
                            color: $main-green !important;
                            font-size: 1.8em;
                        }
                    }
                }

            }
        }
    }

    &-not-sure {
        text-align: center;
        color: $main-white;
        font-family: "Gilroy ExtraBold", Sans-serif;
        font-size: 82px;
        font-weight: 900;
        line-height: 75px;
        padding-top: 100px !important;
        -webkit-font-smoothing: antialiased;

        &-button {
            text-align: center;

            &-text {
                font-family: "Gilroy ExtraBold", Sans-serif;
                font-size: 22px;
                font-weight: 900;
                background-color: #56E1D2;
                border-radius: 30px 30px 30px 30px;
                padding: 20px 20px 20px 20px;
                -webkit-font-smoothing: antialiased;
            }

            @media (max-width: 992px) {
                &-text {
                    font-size: 19px;
                }
            }

            &-text:hover {
                box-shadow: 0 2px 30px rgba(14.796195652173918, 203.75277646502832, 206.24999999999997, 0.5);
            }
        }

        &-text {
            height: 4em;
            position: relative;
            display: block;
            box-sizing: border-box !important;
        }
    }

    @media (max-width: 992px) {
        &-not-sure {
            font-size: 42px;
            padding-top: 80px !important;
        }
    }
}


.disable-padding {
    padding: 0;
}

@media (max-width: 992px) {
    .mobile-padding {
        padding-top: 40px;
    }
}

.desc-padding {
    padding: 130px 0px 0px 40px;
}

@media (max-width: 992px) {
    .desc-padding {
        padding: 80px 0px 0px 40px !important;
    }
}

.fa-check {
    color: $main-green;
    font-size: 22px;
}

.elementor-widget-container {
    margin-top: 70%;
}

.padding-number-count {
    padding-top: 0px !important;
}

@media (max-width: 760px) {
    .col-2 {
        margin-left: auto;
        margin-right: auto;
    }

    .elementor-widget-container {
        margin-top: 0%;
    }
}

@media (max-width: 991px) {
    .QA-section {
        font-size: 15px;
    }
}

@media (min-width: 992px) {
    .Qa-section-industries-margin {
        margin-bottom: 1.5rem !important;
    }
}

.Qa-section-row-padding {
    padding-inline: 10px;
}

@media (max-width: 992px) {
    .Qa-section-row-padding {
        padding-inline: 5px;
    }
}

.QA-section-quote-row {
    margin-bottom: 100px;
}

@media (max-width: 992px) {
    .QA-section-clients-logo {
        max-width: 100%;
    }
}

@media (max-width: 992px) {
    .QA-section-quote-row {
        margin-bottom: 40px;
    }
}

.tt-span-green {
    color: $main-green;
    font-size: 30px;
    font-weight: 700;
}

.home-background-image {
    background: url("../images/first-section-bg.jpg") !important;
}

.QA-section-hide {
    display: none; /* Skriveno po defaultu */
    transition: all 1.3s ease; /* Glatki prelaz */
    font-family: "Gilroy Light", Sans-serif;
    text-decoration: none;
}

.QA-section-card-first.active .QA-section-hide {
    display: block; /* Prikaz kada je aktivan */
    
        color: $main-white;
    .tt-image-hide {
        display: none;
    }
}

.QA-section-card-first.active .QA-section-card-box {
    background-color: #13162f;
    transition: background-color 2.3s ease;
}

.QA-section-card-first.active .tt-image-hide {
    display: none;
}

.QA-section-card-first.active .QA-section-card-text-title {
    color: $main-white;
}

.QA-section-card-first.active .QA-section-card-box:hover {
    text-decoration: none;
}

.tt-QA-navigation-link-bold {
    color: #404040;
    text-decoration: underline !important;
}

.tt-QA-navigation-link-bold:hover {
    color: $main-green;
}