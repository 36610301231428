.case-studies {
    font-family: "Gilroy Light", Sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.6em;
    letter-spacing: 1px;
    overflow-x: hidden;
}

.case-studies-padding {
    padding-top: 120px !important;
}

.case-studies-container {
    display: flex;
    justify-content: center;
}

.case-studies-card {
    background-color: #222242;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 25px;
    color: $main-white;
    width: 40%;
    flex: 0 1 calc(30% - 20px);
    margin: 0 20px 20px 20px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.case-studies-header {
    justify-content: space-between;
    align-items: center;
}


.badge {
    background-color: #32324F;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
}

.case-studies-subtitle {
    font-size: 26px;
    color: $main-orange;
}

.case-studies-paragraph {
    margin-top: 15px;
    font-size: 16px;
    line-height: initial;

}

.fontSize13 {
    font-size: 15px;
}

.case-studies-body {
    margin-top: 20px;
    flex-grow: 1;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.tags span {
    background-color: #32324F;
    padding: 2px 7px;
    border-radius: 5px;
    font-size: 12px;
    letter-spacing: normal;
    border: 1px solid white;
}

.hr-border-line {
    border-bottom: 1px solid white
}

.case-studies-details {
    list-style-type: none;
    padding: 0px;
    line-height: normal;
}

.read-more-btn-left {
    display: inline-block;
    margin-top: 15px;
    padding: 10px 15px;
    background-color: #2CE1D2;
    color: white;
    text-decoration: none;
    border-radius: 5px;
}

.read-more-btn-right {
    display: inline-block;
    margin-top: 15px;
    padding: 10px 15px;
    background: #e86c36;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    margin-left: auto;
}

.read-more-btn-left:hover {
    box-shadow: 0 10px 30px rgba(44, 225, 210, 0.3);
}

.read-more-btn-right:hover {
    box-shadow: 0 10px 30px $main-orange-shadow
}

.paddong-top-section {
    padding-top: 0px !important;
}

.case-studies {
    background-image: url(../images/fifth-section-bg.png);
    color: $main-body-color;
    margin-top: -15px;
    background-repeat: no-repeat;
    background-size: cover;
}


.case-studies-container {
    display: flex;
    flex-wrap: wrap;
}


.tooltip-test {
    position: relative;
    display: inline-block;
    text-decoration: underline $main-orange;
    -webkit-text-decoration: underline $main-orange;
}

.tooltip-test .tooltiptext-test {
    visibility: hidden;
    background-color: #fff;
    color: black;
    border-radius: 6px;
    padding: 10px 10px;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    font-size: 14px;
    width: 100%;
    line-height: initial;
}

.tooltip-test:hover .tooltiptext-test {
    visibility: visible;
}

.case-studies-title-page {
    font-family: "Gilroy ExtraBold", Sans-serif;
    font-size: 48px;
    font-weight: 800;
    letter-spacing: 2px;
    text-align: left;
    -webkit-font-smoothing: antialiased;
}

.case-studies-title-line {
    content: '';
    width: 68px;
    height: 13px;
    background: $main-green;
    z-index: 5;
    align-self: center;
}


.case-studies-title {
    padding: 35px 0 35px 0;
    justify-items: center;
    color: $main-green;
}

.tt-display-flex {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    gap: 10px;
}

@media (min-width: 1400px) {

    .read-more-btn-left,
    .read-more-btn-right {
        font-size: 15px !important;
    }
}

@media (max-width: 1400px) {
    .case-studies-card {
        flex: 0 1 100%;
    }
}

@media (max-width: 992px) {
    .case-studies-title-page {
        font-size: 36px;
    }


    .case-studies-title-line {
        width: 43px;
        height: 8px;
    }
}

@media (max-width: 650px) {

    .read-more-btn-left,
    .read-more-btn-right {
        padding: 5px 7px !important;
        font-size: 13px !important;
    }

    .case-studies-subtitle {
        font-size: 20px;
    }

    .case-studies-padding {
        font-size: 20px;
    }

    .case-studies {
        padding-inline: 0px !important;
    }
}

@media (max-width: 400px) {
    .tt-display-flex {
        display: block;
    }
}