.tt-services-section {
    background-image: url(../images/fifth-section-bg.png);
    color: $main-body-color;
    padding-block: 20px;
    // padding-inline: 23px;

    &-service-item {
        position: relative;
        height: 100%;
        z-index: 1;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        &:hover {
            background-color: $main-white;

            .tt-services-section-service-name {
                color: $main-green
            }

            .qualityAssurance-image {
                background-image: url(../images/qualityAssurance-image-hover.png);
            }

            .development-image {
                background-image: url(../images/development-image-hover.png);
            }

            .userExperience-image {
                background-image: url(../images/userExperience-image-hover.svg);
            }
        }
    }


    @media (max-width: 1024px) {
        &-service-item {
            margin-inline: 20px;
        }
    }

    &-service-name {
        font-size: 26px;
        font-weight: 700;
        font-family: "Gilroy ExtraBold";
        color: $main-body-color;
        text-transform: uppercase;
        -webkit-transition: color 0.1s ease 0s;
        -moz-transition: color 0.1s ease 0s;
        -ms-transition: color 0.1s ease 0s;
        -o-transition: color 0.1s ease 0s;
        transition: color 0.1s ease 0s;
        -webkit-font-smoothing: antialiased;
    }

    &-service-text {
        color: $main-body-color;
        margin: 20px 0 30px;
        // padding-inline: 23px;
    }

    @media screen and (max-width: 601px) {
        &-service-text {
            font-size: 15px;
            line-height: 1.5em;
        }
    }

    &-service-image {
        padding-top: 45%;
        background-repeat: no-repeat;
        background-size: 150px;
        background-position: center;

        &.userExperience-image {
            background-image: url(../images/userExperience-image.svg);
        }

        &.qualityAssurance-image {
            background-image: url(../images/qualityAssurance-image.png);
            background-size: 116px;
        }

        &.development-image {
            background-image: url(../images/development-image.png);
            background-size: 100px;
        }
    }

    @media (max-width: 992px) {
        &-service-image {
            padding-top: 25%;
        }
    }
    @media (max-width: 510px) {
        &-service-image {
            padding-top: 45% !important;
        }
    }
}

@media (max-width: 992px) {
    .tt-services-section {
        background-image: none;
        padding-top: 1rem;
    }
}
