.tt-case-study {
    background-color: #222242;
    color: $main-white;
    font-family: "Gilroy Light", Sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.6em;
    letter-spacing: 1px;

    &-padding {
        padding-inline: 110px !important;
        padding-top: 100px;
    }

    @media (max-width: 1200px) {
        &-padding {
            padding-inline: 30px !important;

        }
    }

    @media (max-width: 992px) {
        &-padding {
            padding-top: 30px !important;

        }
    }

    &-border {
        border-style: solid;
        border-color: #CCCCCC;
        border-width: 0px 1px 0px 1px;
    }

    &-title-page {
        color: $main-green;
        font-family: "Gilroy Light", Sans-serif;
        font-size: 45px;
        font-weight: 700;
        line-height: 1.4em;
        letter-spacing: 2px;
        -webkit-font-smoothing: antialiased;
    }

    &-big-title {
        font-family: "Gilroy Light", Sans-serif;
        font-size: 40px;
        font-weight: 900;
        line-height: 1.4em;
        letter-spacing: 2px;
        -webkit-font-smoothing: antialiased;
        padding-block: 30px;
    }

    &-title-on-section {
        font-family: "Gilroy Light", Sans-serif;
        font-size: 33px;
        font-weight: 700;
        line-height: 1.4em;
        letter-spacing: 2px;
        -webkit-font-smoothing: antialiased;
        padding-block: 30px;
    }

    &-subtitle-on-section {
        font-family: "Gilroy Light", Sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 1.4em;
        letter-spacing: 2px;
        -webkit-font-smoothing: antialiased;
        padding-block: 30px;
    }

    &-paragraph-text {
        padding-block: 10px;
    }

    &-introduction {
        padding-block: 50px;
    }

    &-row-padding {
        padding-inline: 10px;
    }

    @media (max-width: 992px) {
        &-row-padding {
            padding-inline: 5px;
        }
    }
}

.tt-case-study-card {
    right: -106px;
    top: 25px;
    z-index: 1029;
    color: $main-white;
    background-color: #18182f;
    padding: 30px !important;
    border-radius: 15px;
    border: 1px solid $main-white;

    &-overview {
        font-size: 18px;
    }

    &-hr {
        border-bottom: 1px solid black
    }

    &-hr-white {
        border-bottom: 1px solid $main-white
    }

   

    &-text {
        font-size: 15px;
        line-height: 1.3em;
        margin-top: 10px
    }
}

@media (max-width: 1155px) {
    .tt-case-study-card {
        right: -35px;
    }
}

@media (max-width: 992px) {
    .tt-case-study-card {
        right: auto;
        top: auto;
    }
    .tt-case-study {
        font-size: 15px;
    }
    .tt-case-study-white-background-border {
        font-size: 15px;
    }
}
.tt-social-icons a {
    text-decoration: none;
    color: white;
    font-size: 26px;
    display: inline-block;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
    align-content: center;
    margin-right: 10px;
}

.tt-social-icons a:hover {
    color: $main-orange;
    transform: scale(1.1);
}

.tt-case-study-white-background {
    background-color: #F6F6F6;
    font-family: "Gilroy Light", Sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.6em;
    letter-spacing: 1px;

    &-padding {
        padding-inline: 110px !important;
    }

    &-border {
        border-style: solid;
        border-color: #CCCCCC;
        border-width: 0px 1px 0px 1px;
    }

    @media (max-width: 1200px) {
        &-padding {
            padding-inline: 30px !important;
        }
    }

}

.replase-margin-top {
    padding-top: 0px !important;
}

.slow-collapse {
    transition: height 7s ease-in;
    border: 1px solid $main-white;
    padding: 20px;
    border-radius: 15px;
    background-color: #18182f;
}

.form-group {
    padding: 10px;
}

.case-study-input{
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border-radius: 4px;
    box-sizing: border-box;
    font-family: "Gilroy Light", Sans-serif;
    background-color: transparent !important;
    color: $main-white !important;
    border-bottom: 2px solid white !important;
    padding: 15px 20px 0px 20px !important;
    box-shadow: none !important;
}

.case-study-input::placeholder {
    color: #6c757d !important;
}

.tt-list-group-nav-link {
    padding: 5px;
    border-bottom: 1px solid $main-white;
    align-content: center;
    padding-bottom: 10px;
}

.tt-list-group-nav-link:hover {
    transform: scale(1.01);
}

.list-group {
    gap: 10px;
    align-content: center;
}

.arrow-svg {
    width: 30px;
}
.test-test {
   background-color: $main-orange !important;
   -webkit-box-sizing: content-box;
   -moz-box-sizing: content-box;
   box-sizing: content-box;
   width: 40px;
   height: 40px;
   border: none;
   -webkit-border-radius: 50%;
   border-radius: 50%;
   display: inline-block;
   text-align: center;
   align-content: center;
}

.tt-email-us {
    text-align: center;
    padding: 30px;
    border-radius: 15px;
}

.text-align-center {
    text-align: center;
}

.align-items-center {
    align-items: center;
}

.padding-left-20 {
    padding-left: 20px;
}

.text-align-end {
    text-align: end !important;
}

.float-end {
    float: inline-end;
}
