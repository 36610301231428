.tt-careers-section {
  height: 100vh;

  .grid-container {
    display: flex;
    grid-template-columns: auto auto;
    padding: 10px;
  }

  @media screen and (max-width: 666px) {
    .grid-container {
      grid-template-columns: none;
      display: grid;
    }

    .tt-careers-section-button {
      font-size: 15px;
    }
  }

  .grid-item {
    border: 1px solid rgba(0, 0, 0, 0.8);
    font-size: 30px;
    text-align: center;
    height: 420px;
    width: 434px;
    margin: 2px 2px 2px 2px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media (max-width: 991px) {
    .grid-item {
      width: 314px;
    }
    .tt-product-section-puzzles-cloud {
      width: 634px; 
    }
  }

  @media (min-width: 992px) {
    & .featured-text {
      padding: 0 0 0 2rem;
      border-left: 0.5rem solid $main-green;
    }
  }

  &-card {
    background-image: url(../images/carrer-e1689679204355.png);
  }

  &-button {
    padding: 8px 10px 8px 10px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    font-family: "Gilroy ExtraBold";
    background-color: #02010100;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    margin-top: 30px;
    -webkit-font-smoothing: antialiased;
  }

  @media screen and (max-width: 820px) {
    .tt-careers-section-button {
      font-size: 15px;
    }
  }

  &-button:hover {
    background-color: $main-green;
    border: 1px solid $main-green;
  }

  &-product-cover {
    background-color: #1F1F39A8;
    font-weight: bold;
    z-index: 2;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  &-product-cover:hover {
    background-color: #1f1f39d4;
    transition: 0.3s;

    .text-up-careers-headings {
      top: -30px !important;
    }
  }

  &-product-card-height {
    height: 50px;
  }

  &-product-card-text {
    font-size: 21px;
    color: $main-white;
  }

  &-padding {
    padding-top: 180px !important;
    padding-bottom: 100px !important;
    padding-inline: 20px !important;
  }

  @media (max-height: 768px) and (max-width: 1368px) {
    &-padding {
      padding-top: 80px !important;
      padding-bottom: 0px !important;
    }

    .grid-item {
      height: 320px;
      
    }

    .tt-product-section-puzzles-cloud {
      width: 320px; 
    }

    .text-up-careers {
      padding: 150px 30px 43px 30px;
    }

    .about-company-section {
      padding-inline: 10px !important;
    }
  }

  @media (max-height: 766px) and (max-width: 1368px) {
    &-padding {
      padding-top: 80px !important;
      padding-bottom: 0px !important;
    }

    .grid-item {
      height: 320px;
    }

    .tt-product-section-puzzles-cloud {
      width: 320px; 
    }

    .text-up-careers {
      padding: 150px 30px 43px 30px;
    }

    .about-company-section {
      padding-inline: 10px !important;
    }
  }
}

@media (max-width: 992px) {
  .tt-careers-section-padding {
    padding-top: 80px !important;
  }

  .tt-careers-section {
    background-image: url('');
  }
}

.text-up-careers {
  // position: relative;
  // top: 0;
  // transition: top 1s ease 0s;
  padding: 127px 30px 150px 30px;
}

// .text-up-careers:hover {
//   top: -30px;
// }

.text-up-careers-headings {
  position: relative;
  top: 0;
  transition: top 1s ease 0s;
}

.text-up-careers a {
  box-shadow: none;
  text-decoration: none;
}