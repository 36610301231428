.tt-contact-section {
  background-image: url(../images/contact-bg.png);
  color: $main-white;
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-inline: 23px;

  &-border {
    border-style: solid;
    border-color: $main-white;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    border-width: 1px 0px 0px 1px;
    padding-top: 25px;
  }

  &-heading-title {
    color: $main-white;
    font-family: "Gilroy Light", Sans-serif;
    font-weight: 300;
    padding-top: 30px;
    font-size: 20px;
  }

  @media (max-width: 992px) {
    .tt-contact-home-section-heading-title {
      font-size: 15px;
    }
  }

  ul {
    list-style-type: none;
    margin-top: 5%;
    margin-left: -25px;

    li {
      margin-top: 20px;
    }

    a {
      color: $main-white;
      font-family: "Gilroy Light", Sans-serif;
      font-size: 20px;
      font-weight: 300;
      text-decoration: none;
    }

    a:hover {
      color: $main-green;
    }
  }
}

.contact-section {
  background: $main-white;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

input[type=text],
input[type=email] {
  width: 100%;
  padding: 0px 20px 0px 5px;
  margin: 8px 0;
  display: inline-block;
  border: none;
  border-bottom: 2px solid black;
  box-sizing: border-box;
  font-family: "Gilroy Light", Sans-serif;
  background: transparent;
  -webkit-appearance: none;
  appearance: none; 
  outline: none; 
  border-radius: 0; 
}

input[type=text]:focus, input[type=email]:focus, textarea:focus {
  outline: none; 
  border-bottom: 2px solid $main-green; 
}

textarea {
  width: 100%;
  padding: 0px 20px;
  margin: 8px 0;
  display: inline-block;
  border: none;
  border-bottom: 2px solid black;
  box-sizing: border-box;
  font-family: "Gilroy Light", Sans-serif;
  background: transparent;
  -webkit-appearance: none; 
  appearance: none; 
  outline: none;
  border-radius: 0; 
}

#snackbarFailed {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: transparent;
  color: #C10011;
  ;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
}

#snackbarFailed.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

#snackbarSuccess {
  visibility: hidden;
  min-width: 250px;
  margin-left: -155px;
  background-color: transparent;
  color: $main-green;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
}

#snackbarSuccess.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}