.tt-go-park {
    &-cover {
        background-image: url(../images/CoverImg.png);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 480px;
        background-color: #f7f9fb;
    }

    h1 {
        color: $main-green;
        font-family: "Gilroy ExtraBold", Sans-serif;
        font-size: 60px;
        font-weight: 900;
        text-align: center;
        -webkit-font-smoothing: antialiased;
    }

    h2 {
        color: #222222;
        font-family: "Gilroy ExtraBold", Sans-serif;
        font-size: 30px;
        font-weight: 800;
        -webkit-font-smoothing: antialiased;
        
    }

    h2:after {
        content: '';
        display: block;
        position: inherit;
        bottom: 0;
        left: 0;
        height: 5px;
        width: 90px;
        background: $main-green;
    }

    h4 {
        font-size: 19px;
    }

    p {
        color: #282828;
        font-family: "Gilroy Light", Sans-serif;
        font-size: 21px;
        font-weight: 300;
    }

    @media (max-width: 992px) {
        p {
            font-size: 17px;
        }
    }

    li::marker {
        color: $main-green;
        font-size: 1.8em;
    }

    span {
        font-family: "Gilroy Light", Sans-serif;
        font-size: 21px;
        font-weight: 300;
    }

    &-back-to-products {
        text-align: end;

        a {
            font-family: "Gilroy ExtraBold", Sans-serif;
            font-weight: 900;
            fill: #2F2E2E;
            color: #2F2E2E;
            background-color: #61CE7000;
            -webkit-font-smoothing: antialiased;
        }

        a:hover {
            color: $main-green;
        }
    }

    @media (max-width: 992px) {
        &-back-to-products {
            text-align: center;
        }
    }

    &-try-it {
        text-align: left;

        a {
            font-family: "Gilroy ExtraBold", Sans-serif;
            font-weight: 900;
            background-color: $main-green;
            border-radius: 20px 20px 20px 20px;
            padding: 15px 50px 15px 50px;
            -webkit-font-smoothing: antialiased;

        }

        a:hover {
            box-shadow: 0 2px 30px rgba(44, 225, 210, 0.3);
        }
    }

    @media (max-width: 992px) {
        &-try-it {
            text-align: center;

            a {
                padding: 10px 20px 10px 20px;
            }
        }
    }

}