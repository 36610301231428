.tt-clients-section {
  background-image: url(../images/contact-bg.png);
  color: $main-white;
  padding-block: 20px;
  // padding-inline: 23px;

  p {
    padding-bottom: 5rem;
    margin-top: 2rem;
  }

  &-logo {
    max-width: 100%;
    height: 110px;
  }

  &-title {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 700;
    font-family: "Gilroy ExtraBold";
    color: $main-white;
    text-transform: uppercase;
    -webkit-transition: color 0.1s ease 0s;
    -moz-transition: color 0.1s ease 0s;
    -ms-transition: color 0.1s ease 0s;
    -o-transition: color 0.1s ease 0s;
    transition: color 0.1s ease 0s;
    -webkit-font-smoothing: antialiased;
  }

  &-desc {
    font-size: 15px;
    color: $main-white;
    font-family: "Gilroy Light";
  }

  &-customer {
    margin-top: 40px;
    margin-bottom: 15px;
    color: $main-white;
    font-family: "Gilroy ExtraBold", Sans-serif;
    font-size: 41px;
    font-weight: 500;
    letter-spacing: 2px;
    -webkit-font-smoothing: antialiased;
  }
}