.about-company {
    background-image: url(../images/about-company-bg.png);
    color: #404040;
    font-family: "Gilroy Light", Sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.6em;
    letter-spacing: 1px;

    &-section {
        // padding-inline: 110px !important;
        padding-top: 20px;
        border-style: solid;
        border-color: #CCCCCC;
    }

    @media (max-width: 768px) {
        &-section {
            padding-inline: 30px !important;
        }
    }

    @media (max-width: 991px) and (min-width: 768px) {
        &-section {
            padding-inline: 100px !important;
        }
    }

    &-section-team {
        background-image: url(../images/techtailors-team.jpg);
        background-position: bottom;
        background-size: contain;
        background-repeat: no-repeat;
        height: 660px;
    }

    .line::before {
        content: '';
        position: absolute;
        top: 50px;
        left: 0;
        width: 68px;
        height: 13px;
        background: #2ce1d2;
        transform: translateX(-50%);
        z-index: 5;
    }

    @media (max-width: 991px) {
        .line::before {
            width: 40px;
            height: 8px;
        }
    }
}

@media (max-width: 991px) {
    .about-company-section-team {
        background-size: cover;
    }
}

@media (max-width: 1150px) {
    .about-company-section-team {
        height: 410px
    }
}

@media (max-width: 1199px) {
    .about-company-section-team {
        height: 467px;
    }
}

@media (max-width: 1399px) and (min-width: 1199px) {
    .about-company-section-team {
        height: 583px;
    }
}

@media (max-width: 991px) {
    .tabs-mobile {
        width: auto;
        line-height: 1.5;
    }

}

@media (max-width: 991px) {
    .about-company {
        padding-top: 10px !important;
    }
}

.tabs-verical .nav-link {
    font-size: 15px;
}

.slider-title {
    font-size: 30px;
    text-align: center;
    color: $main-white;
    font-family: "Gilroy ExtraBold", Sans-serif;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -1px;
    margin-top: 23px;
    -webkit-font-smoothing: antialiased;
}
@media (max-width: 991px) {
.slider-title {
    font-size: 20px;
  
}
}

.carousel-item {
    height: calc(100vh - 75px);
    min-height: 540px;
    background: no-repeat center center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

@media (max-height: 800px) and (min-width: 1100px) {
    .carousel-item {
        min-height: 640px;
    }
}

.carousel-indicators [data-bs-target] {
    background-color: $main-green;
}

.slider {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        size: 100px;
    }
}

.gallery img {
    cursor: pointer;
}

#gallery-modal .modal-img {
    width: 100%;
}

.tabs-verical .nav-link {
    color: $main-body-color;
    font-family: "Gilroy ExtraBold", Sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.5em;
    -webkit-font-smoothing: antialiased;
}

@media (max-width: 991px) {
    .tabs-verical .nav-link {
        font-size: 15px;
    }
}

.tabs-verical .navbar-default .navbar-text {
    color: $main-white;
}

.tabs-verical .nav-tabs>li.active>a,
.tabs-verical .nav-tabs>.profile-posts li.active>a:focus,
.tabs-verical .nav-tabs>li.active>a:hover {
    color: $main-green;
    cursor: pointer;
}

@media (max-width: 991px) {
    h4 {
        font-size: 15px !important;
    }

    .first {
        border: none;
    }

    .second {
        border: none;
    }
}


@media (max-width: 1200px) {
    .tabs-verical {
        height: auto !important;
    }
}

.tabs-verical {
    height: 390px;
}

.first {
    border-width: 1px 1px 0px 0px;
}

.second {
    border-width: 0px 0px 1px 1px;
}

.third {
    border: none;
}

@media (max-height: 768px) and (max-width: 1368px) {
    .slider-margin {
        margin-top: 50px !important;
    }
}

@media (max-height: 766px) and (max-width: 1368px) {
    .slider-margin {
        margin-top: 50px !important;
    }
}

@media (max-width: 991px) {
    .about-company {
        font-size: 17px;
    }
}

.disable-slider-padding {
    padding-top: 0px !important;
}