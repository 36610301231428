.main-menu {
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
  }

  >ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    >li {
      list-style: none;
      font-size: 0.9rem;

      >a {
        display: inline-block;
        text-decoration: none;
        font-family: "Gilroy Light", Sans-serif;
        font-size: 18px;
        color: #fff;
        padding: 1.5rem 1.5rem 1.25rem;
        border-bottom: 0.25rem solid transparent;

        &:hover {
          color: $main-green;
          text-decoration: none;
        }
      }

      &.active {
        >a {
          color: $main-green;
          text-decoration: none;
        }
      }
    }
  }
}