.logo {
  display: none;
  @include media-breakpoint-up(lg) {
    display: block;
    width: 120px;
  }
  img {
    width: 50%;
    height: auto;
  }
  a {
    display: flex;
    width: 100%;
    height: 100%;

    h1 {
      margin-left: 10%;
      margin-top: 1%;
    }
  }
}
.logo-mobile {
  display: block;
  width: 25px;
  @include media-breakpoint-up(lg) {
    display: none;
  }
  img {
    width: 100%;
    height: auto;
  }
  a {
    display: block;
    width: 100%;
    height: 100%;
  }
}
