.tt-performance-testing-section {
    font-family: "Gilroy Light", Sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.6em;
    letter-spacing: 1px;

    &-padding {
        padding-inline: 110px !important;
    }

    &-link {
        text-decoration: underline !important;
        color: #404040;
    }

    &-link-white {
        text-decoration: underline !important;
        color: #ffffff;
    }

    &-link:hover {
        color: $main-green;
    }

    &-link-white:hover {
        color: $main-green;
    }

    @media (max-width: 1200px) {
        &-padding {
            padding-inline: 30px !important;

        }
    }

    &-row-padding {
        padding-inline: 10px;
    }

    @media (max-width: 992px) {
        &-row-padding {
            padding-inline: 5px;
        }
    }

    &-border {
        border-style: solid;
        border-color: #CCCCCC;
        border-width: 0px 1px 0px 1px;
    }

    &-image {
        background-image: url(../images/performance-testing.png);
        background-position: bottom;
        background-size: contain;
        background-repeat: no-repeat;
        min-height: 200px;
        opacity: 0.8;
    }

    &-image-why {
        background-image: url(../images/performance-testing-why.png);
        background-position: bottom;
        background-size: contain;
        background-repeat: no-repeat;
        min-height: 300px;
    }

    &-title-page {
        color: $main-green;
        font-family: "Gilroy ExtraBold", Sans-serif;
        font-size: 33px;
        font-weight: 800;
        line-height: 1.4em;
        letter-spacing: 2px;
        -webkit-font-smoothing: antialiased;
    }

    &-title-page:after {
        content: '';
        display: block;
        margin-top: 5px;
        height: 5px;
        width: 90px;
        background: #fff;
    }

    &-small-title {
        font-family: "Gilroy ExtraBold", Sans-serif;
        font-size: 21px;
        font-weight: 800;
        -webkit-font-smoothing: antialiased;

    }

    &-what-is-performance {
        ul {
            li {
                padding-top: 15px;

                span {
                    font-family: "Gilroy Light", Sans-serif;
                    font-size: 17px;
                    font-weight: 300;
                }
            }

            li::marker {
                color: $main-green !important;
                font-size: 25px;
            }
        }
    }

    @media (max-width: 991px) {
        &-what-is-performance {
            ul {
                li {
                    span {
                        line-height: 1.2em;
                    }
                }
            }
        }
    }

    &-subtitle {
        padding: 130px 0px 0px 0px;
        color: #222222;
        font-family: "Gilroy ExtraBold", Sans-serif;
        font-size: 30px;
        font-weight: 700;
        -webkit-font-smoothing: antialiased;
    }

    @media (max-width: 992px) {
        &-subtitle {
            padding: 80px 0px 0px 0px;
        }
    }

    &-subtitle-dark {
        padding: 130px 0px 0px 0px;
        color: #FFFFFF;
        font-family: "Gilroy ExtraBold", Sans-serif;
        font-size: 30px;
        font-weight: 700;
        -webkit-font-smoothing: antialiased;
    }

    &-subtitle-dark:after {
        content: '';
        display: block;
        margin-top: 5px;
        height: 5px;
        width: 90px;
        background: $main-green;
    }

    @media (max-width: 992px) {
        &-subtitle-dark {
            padding: 80px 0px 0px 0px;
        }
    }

    &-why-choose {
        font-family: "Gilroy ExtraBold", Sans-serif;
        font-weight: 900;
        -webkit-font-smoothing: antialiased;
    }

    .tt-performance-testing-section-ul {
        ul {
            margin-left: -25px;

            li {
                align-items: center;
                display: flex;
                font-size: inherit;
                margin-bottom: 20px;

                span {
                    font-size: 19px;
                }
            }
        }
    }

    &-not-sure {
        text-align: center;
        font-family: "Gilroy ExtraBold", Sans-serif;
        font-size: 82px;
        font-weight: 900;
        line-height: 75px;
        padding-top: 100px !important;
        -webkit-font-smoothing: antialiased;

        &-button {
            text-align: center;

            &-text {
                font-family: "Gilroy ExtraBold", Sans-serif;
                font-size: 22px;
                font-weight: 900;
                background-color: #56E1D2;
                border-radius: 30px 30px 30px 30px;
                padding: 20px 20px 20px 20px;
                -webkit-font-smoothing: antialiased;
            }

            &-text:hover {
                box-shadow: 0 10px 30px rgba(44, 225, 210, 0.3);
            }
        }

        &-text {
            height: 4em;
            position: relative;
            display: block;
            box-sizing: border-box !important;
        }
    }
}

@media (max-width: 992px) {
    .tt-performance-testing-section {
        font-size: 17px;
    }
}